import { Card, Container } from "react-bootstrap";

function Home() {
  return (
    <Container style={{ padding: '2rem' }}>
      <Card>
        <Card.Body>
          <Card.Title>Welcome!</Card.Title>
          <Card.Text>
            Feel free to look around, this a site I've designed in my free time with the goal of adding interesting features.
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Home;