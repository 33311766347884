import { UIView, useSrefActive } from '@uirouter/react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import './App.css';
import Home from './components/Home';
import Navigator from './components/Navigator';

function App() {
  return (
    <div>
      <Navigator />
      <UIView />
    </div>
  );
}

export default App;
