// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { UIRouter } from '@uirouter/react/lib/components';
// import Home from './components/Home';
// import About from './components/About';
// import { pushStateLocationPlugin } from '@uirouter/react';

// //For React 18, eventually
// // const root = ReactDOM.createRoot(
// //   document.getElementById('root') as HTMLElement
// // );

// const helloState = { name: "home", url: "/home", component: Home };
// const aboutState = { name: "about", url: "/about", component: About };

// ReactDOM.render(
//   <UIRouter 
//     plugins={[pushStateLocationPlugin]} 
//     states={[helloState, aboutState]}>
//     <App />
//   </UIRouter>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import ReactDOM from "react-dom";
import { UIRouter, pushStateLocationPlugin } from "@uirouter/react";
import Home from "./components/Home";
import About from "./components/About";
import App from "./App";
import Pemdash from "./components/games/Pemdash";
import './index.css';
import Contact from "./components/Contact";
import BinaryGame from "./components/games/BinaryGame";
import Stories from "./components/Stories";

const defaultState = { name: "default", url: "/", component: Home };
const helloState = { name: "home", url: "/home", component: Home };
const aboutState = { name: "about", url: "/about", component: About };
// const pemdashState = { name: "pemdash", url: "/games/pemdash", component: Pemdash };
const storiesState = { name: "stories", url: "/stories", component: Stories };
const contactState = { name: "contact", url: "/contact", component: Contact };
const binaryGameState = { name: "binary", url: "/games/bi-squary", component: BinaryGame };

ReactDOM.render(
  <UIRouter 
    plugins={[pushStateLocationPlugin]} 
    states={[defaultState, helloState, aboutState, storiesState, contactState, binaryGameState]}>
    <App/>
  </UIRouter>,
  document.getElementById("root")
);
