import { useEffect, useRef, useState } from 'react'
import { Container, Card, Button, Form, Alert } from 'react-bootstrap'
import './BinaryGame.css'


const BinaryGame = () => {
    const tries = 3
    const [attempts, setAttempts] = useState(tries)
    const [retry, setRetry] = useState(false)
    const [array, setArray] = useState([[0,0,0], [0,0,0], [0,0,0]])
    const [columnValues, setColumnValues] = useState([[0,0,0], [0,0,0]])
    const [rowValues, setRowValues] = useState([[0,0,0], [0,0,0]])
    const [totals, setTotals] = useState([0,0,0,0])
    const [solved, setSolved] = useState('')
        
    useEffect(() => {
        setAttempts(tries)
        setRetry(false)
        let puzzleSolve = Array.from({length: 3}, () => (Array.from({length: 3}, () => Math.floor(Math.random() * 2))));
        setArray(puzzleSolve)
        setTotals(findTotals(puzzleSolve))
        setRowValues([[0,0,0], [0,0,0]])
        setColumnValues([[0,0,0], [0,0,0]])
        setArray([[0,0,0], [0,0,0], [0,0,0]])
    }, [retry])

    useEffect(() => {
        setRowValues(findRowValues(array))
        setColumnValues(findColumnValues(array))
    }, [array])

    const findRowValues = (ary: number[][]) => {
        let leftArray: number[] = []
        let rightArray: number[] = []

        rowValues[0].forEach((e, i) => {
            let left = findRowValue(true, i, ary)
            let right = findRowValue(false, i, ary)
            leftArray.push(left)
            rightArray.push(right)
        })

        return [leftArray, rightArray]
    }

    const findRowValue = (left: boolean, index: number, array: number[][]) => {
        let value = 0

        if(left) {
            value = parseInt(array[index].slice().reverse().join(''), 2)
        } else {
            value = parseInt(array[index].join(''), 2)
        }

        return value
    }

    const findColumnValue = (top: boolean, index: number, array: number[][]) => {
        let value = 0

        if(top) {
            value = parseInt(array.map((e) => {
                return e[index]
            }).slice().reverse().join(''), 2)
        } else {
            value = parseInt(array.map((e) => {
                return e[index]
            }).join(''), 2)
        }

        return value
    }

    const findColumnValues = (ary: number[][]) => {
        let topArray: number[] = []
        let bottomArray: number[] = []

        columnValues[0].forEach((e, i) => {
            let top = findColumnValue(true, i, ary)
            let bottom = findColumnValue(false, i, ary)
            topArray.push(top)
            bottomArray.push(bottom)
        })

        return [topArray, bottomArray]
    }

    const findTotals = (ary: number[][]) => {
        let totals = [0,0,0,0]
        let leftTotal = 0
        let rightTotal = 0
        let topTotal = 0
        let bottomTotal = 0
        
        let rowValues = findRowValues(ary)
        let columnValues = findColumnValues(ary)
        
        leftTotal = rowValues[0].reduce((sum, value) => { return sum + value})
        rightTotal = rowValues[1].reduce((sum, value) => { return sum + value })
        
        leftTotal = rowValues[0].reduce((sum, value) => { return sum + value})
        rightTotal = rowValues[1].reduce((sum, value) => { return sum + value })

        topTotal = columnValues[0].reduce((sum, value) => { return sum + value})
        bottomTotal = columnValues[1].reduce((sum, value) => { return sum + value })

        topTotal = columnValues[0].reduce((sum, value) => { return sum + value})
        bottomTotal = columnValues[1].reduce((sum, value) => { return sum + value })
        
        totals[0] = topTotal + leftTotal
        totals[1] = topTotal + rightTotal
        totals[2] = bottomTotal + leftTotal
        totals[3] = bottomTotal + rightTotal

        return totals
    }

    const checkAnswer = () => {
        let checkAry = findTotals(array)

        if(checkAry.toString() === totals.toString()) {
            setSolved('RIGHT')
        }
        else {
            setSolved('WRONG')
            if(attempts === 1) {
                setRetry(true)
            } else {
                setAttempts(old => old - 1)
            }
        }
    }

    const generateRowColCard = (value: number, index: number) => {
        return <Card key={index} style={{ borderColor: 'black', color: 'white', backgroundColor: '#212529', width: '6rem', height: '6rem' }}>
                    <Card.Body style={{ userSelect: 'none', alignItems: 'center', fontSize: '40px', display: 'flex',   justifyContent: 'center'}}>
                        {value}
                    </Card.Body>
                </Card>
    }

    const generateTotalsCard = (value: number) => {
        return <Card style={{ borderColor: 'black', width: '6rem', height: '6rem', background: '#02ad02', color: 'white', display: 'flex', }}>
                    <Card.Body style={{ userSelect: 'none', alignItems: 'center', fontSize: '40px', display: 'flex',   justifyContent: 'center'}}>
                        {value}
                    </Card.Body>
                </Card>
    }

    const generateToggleCards = (array: number[][]) => {
        return array.map((value, x) => {
                    return <div key={'toggle'+x} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
                        {generateRowColCard(rowValues[0][x], x)}

                        {value.map((value, y) => {
                            return <Card className='custom-card' key={y} onClick={() => {let a = [...array]; a[x][y] =  value === 0 ? 1 : 0; setArray(a)}} >
                                        <Card.Body style={{ userSelect: 'none', alignItems: 'center', fontSize: '40px', display: 'flex',   justifyContent: 'center'}}>
                                            {array[x][y]}
                                        </Card.Body>
                                    </Card>
                            })
                        }
                        
                        {generateRowColCard(rowValues[1][x], x)}
                    </div>})
    }

    return (
        <div>
            {solved && 
                <Alert style={{ position: 'absolute', width: '100%' }} key={solved === 'WRONG' ? 'danger' : 'success'} variant={solved === 'WRONG' ? 'danger' : 'success'} onClose={() => setSolved('')} dismissible>
                    {solved}
                </Alert>}
            <br/>
            <Container style={{ marginTop: '3rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
                <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
                    <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
                        {generateTotalsCard(totals[0])}
                        {columnValues[0].map((value, index) => {
                            return generateRowColCard(value, index)
                        })}
                        {generateTotalsCard(totals[1])}
                    </Container>
                    <Container>
                        {generateToggleCards(array)}
                    </Container>
                    <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
                        {generateTotalsCard(totals[2])}
                        {columnValues[1].map((value, index) => {
                            return generateRowColCard(value, index)
                        })}
                        {generateTotalsCard(totals[3])}
                    </Container>
                </Container>
                <Button className={'btn'} onClick={() => checkAnswer()}>
                    Check Answer
                </Button>
                <div style={{fontSize: '25px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
                    Tries Remaining: 
                    <br/>
                    <Card style={{ marginTop: '1rem', width: '6rem', height: '6rem', background: '#02ad02', color: 'white', display: 'flex', }}>
                        <Card.Body style={{ userSelect: 'none', alignItems: 'center', fontSize: '40px', display: 'flex',   justifyContent: 'center'}}>
                           {attempts}
                        </Card.Body>
                    </Card>
                </div>
            </Container>
            <br/>
            <Container>
                <Card>
                    <Card.Header style={{ backgroundColor: '#212529', color: 'white' }}>
                        How to Play
                    </Card.Header>
                    <Card.Body>
                        -Click the middle 3x3 grid to change the numbers between 0 and 1.
                        <br/>
                        -This will then update the total values of the bookends of each row and column to be the total value in binary.
                        <br/>
                        -Setting a given row to 0 0 1 will result in the right value resolving to 1, and the left value resolving to 4, as in 0 0 1 backwards, 1 0 0.
                        <br/>
                        -The goal is to find the right combination so that each row and column add together to make up the corner values, e.g. the top left corner should be the sum of the left-most row, and the top-most row of values.
                    </Card.Body>
                </Card>
            </Container>
            <br/>
        </div>
    )
}

export default BinaryGame