import { Card, Container } from "react-bootstrap";

function About() {
    return (
        <Container style={{ padding: '2rem' }}>
            <Card>
                <Card.Body>
                <Card.Title>About Me</Card.Title>
                <Card.Text>
                    My name is Andrew Baker, I'm a developer from Columbus, Ohio. 
                    I have a passion for creativity and learning, and I made this website as a reflection of that. As you can see, there's a games section.
                    As time goes on, I'm hoping to fill this with fun projects that bring some joy as well as show off some things I've picked up in my years of learning software development
                </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default About;