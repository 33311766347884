import { Card, Container } from "react-bootstrap";

function Contact() {
  return (
    <Container style={{ padding: '2rem' }}>
      <Card>
        <Card.Body>
          <Card.Title>Contact</Card.Title>
          <Card.Text>
            <a href="https://www.linkedin.com/in/andrew-baker-38bb56106/">
                My Linked-In
            </a>
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default Contact;