import { useSrefActive } from "@uirouter/react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";


function Navigator() {
  const activeClass = "active";
  const homeSref = useSrefActive("home", {}, activeClass);
  const aboutSref = useSrefActive("about", {}, activeClass);
  const gamesSref = useSrefActive("binary", {}, activeClass);
  const storiesSref = useSrefActive("stories", {}, activeClass);
  const contactSref = useSrefActive("contact", {}, activeClass);
  
  return (
      <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
            <Container fluid>
                <Navbar.Brand {...homeSref}>A Whole Baked Me</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link {...homeSref}>Home</Nav.Link>
                        <Nav.Link {...aboutSref}>About</Nav.Link>
                        <NavDropdown title="Games" id="basic-nav-dropdown">
                            {/* <NavDropdown.Item href="/games/pemdash">
                                PEMDASH
                            </NavDropdown.Item> */}
                            <NavDropdown.Item {...gamesSref}>
                                Bi-Squary
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link {...storiesSref}>Stories</Nav.Link>
                        <Nav.Link {...contactSref}>Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigator;