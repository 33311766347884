import { Container, Card, Accordion } from "react-bootstrap";

function Stories() {
    return (
    <Container style={{ padding: '2rem' }}>
        <Card>
            <Card.Body>
            <Card.Title>Stories</Card.Title>
            <Card.Text>
                This is a place that serves to house all the short stories I've written as a part of my writing group.
            </Card.Text>
            </Card.Body>
        </Card>
        <br/>
        <br/>
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Bad Luck</Accordion.Header>
                <Accordion.Body>
                                    Manus contorted bloody, severed legs and stuffed them into a trash bag. He brought his hands out of the bag, content they would fit, and ungloved his hands, throwing them inside with the limbs. He shut the freezer, then gripped the top of the bag, sealing it shut, and slung it over his shoulder. Trudging up the dusty cellar stairs of his farmhouse, Manus walked back out into the bright summer day and made his way over to his barn, adjusting the bag as the gangly, soft flesh shifted against his back through the bag.

                    “You hungry, boy?” he shouted through the doors. He stuffed a hand in his pocket and pulled out a ring of various keys. Finding the one he needed, he unlocked the padlock and dropped it into the grass. Heavy, deep groans and the smell of hot trash and sulfur enveloped him as he pulled open one of the doors. Light poured into half of the barn, reflecting off the oily green scales of a dragon that laid inside the cramped space, squeezing up against the barn’s wall. It lifted its head, the chain around its neck rattling as the beast as it looked Manus in the eye with question of what he had brought.

                    “Gotcha a snack, big buddy.” he said. Manus chucked the bag inside in front of the dragon, watching the bag flop to the floor and the legs sprawl out, bloody toes peeking out towards his pet. The dragon’s eyes widened and it shot out like a cobra at the meal, shoveling down the leg.

                    “Gods-be-damned Corrus, y’act like I haven’t fed ya in weeks, I swear. You gotta take it slow buddy.”

                    Before Manus had finished his scolding the legs were gone, and the dragon was already shuffling around the back with its nose, searching for anything it may have missed. Manus shook his head and sighed.

                    “Alright buddy, I’ll have some more for ya here soon, don’t ya worry. Gotta get back to work, Corrus, I’ll see ya later. Love ya buddy.”

                    The dragon gave Manus a stoic look as he reached down for the padlocks and slid the barn door shut. He latched it and walked back towards the house, and a cold sweat came over him.

                    “Woulda grabbed the bag if ya weren’t so gods-damned big now, Corrus,” he said to himself, remembering the look the dragon gave him. Being made of his pet’s favorite food didn’t sit well with him, but he couldn’t just let the poor thing starve. Manus stepped onto the back porch of his farmhouse and turned back towards the barn, half expecting to see Corrus burst out, but nothing happened. As he pulled the back door open and stepped into the house, a small gust of wind hit him in the back of the head, and from above, a letter floated down in front of him. 

                            He snatched it out of the air, and flipped it over to read the sender.

                    “From: Gregorius T. Malinthallow”

                    Another cold sweat broke on Manus's forehead as the rough parchment of the magic letter weighed heavy in his hand.

                    “Oh haven't ya done enough to me already,” he said as he ripped the envelope open and pulled out the letter.

                    “My Dearest Manus,

                    How goes it? I just wanted to reach out and extend another thank you on behalf of us here at The Ghost Shop: Wonderment Emporium of Trinkets and Oddities. We sincerely hope your purchase has paid dividends in filling the void in your life. Our deepest condolences for you on the news of your wife, please know you are in our thoughts prayers.

                    Yours truly, 
                    Gregorius T. Malinthallow

                    P.S. Payment for said purchase, plus interest will promptly be expected the first of the month, every month, for seventy two (72) years starting next month. We look forward to your business.”

                    Manus crumpled the letter, then ripped it before throwing it to the floor.

                    “Godsdamned trickster.”

                    He remembered Pol from work warning him about the place, wishing he had listened. There were no get-rich-quick methods, not for someone like him anyway. For him there was only an ever-growing dragon in his barn that needed to be fed with human flesh. The ad had said that any of the eggs had a chance at being goose that laid golden eggs, but only after it hatched did it see the fine print listing the odds of getting the goose as “Less than .1%”

                    Manus walked to the bedroom and began dressing for work. He had an hour before his evening shift at the mage crystal factory. He also had until tomorrow to acquire some more dragon food before Corrus decided he would be his next meal.


                    ***


                            The smell of burning chemicals hit Manus as he stepped into the factory. What the chemicals could be was a company trade secret, but he knew it couldn't be good for him. He clocked in and made his way to the break room to get ready to jump on the line. Manus opened his locker and pulled out his visor. He fit it over his head and then shoved in his food for his meal break and shut the locker. The sound of footsteps from down the hall made him turn, and a second later his manager, Thompson stepped in with someone he had never seen before in toe.

                    “...And this here is the break room. You'll have a locker for your gear, and a break every six hours.”

                    The mystery person took the room in, nodding along as Thumps explained the job. Manus and the person, a smaller guy, young and slim with shaggy grey hair, made eye contact as he looked around the room. Manus ducked his head back down to his locker and closed the door. He walked across the room towards the two others as the new guy asked a question Manus couldn’t hear. It was only then that Thumps seemed to notice him.

                        “Ah Manus, how’s it going? This here is Shal, Alan’s replacement. He’s going to be your new partner in crime on the line.”

                        Thumps chuckled as if he didn’t find his own joke funny. Manus and Shal gave each other curt grins as a greeting.

                        “Nice to meet you.” Manus said.

                        “Thanks, glad to be happy to be here,” Shal said.

                        Thumps interrupted with “Manus here has been our top inspector for, what, twelve years now?”

                        “Seventeen actually, As ’a’ this August, sir.” Manus said.

                        “Boy, doesn't time fly. Anyway Shal, let’s head back to my office and let Manus here get to work.See ya pal, have a good one.”

                        Thumps stiffly patted him in the shoulder, and he and Shall shuffled out of the locker room while Manus hung back before heading out towards his line. 

                    He smiled. Maybe you’ll have a meal here before too long afterall, Cirrus he thought.

                    The factory was a dangerous place to work after all. Crystals exploded sometimes, or even became active and set off spells. Sometimes folks went missing without so much as a trace. It was hard to keep track, and that kept the authorities from poking their heads around too much. Shal could be another face to add to the missing persons list. 

                    If he’s anything like me, nobody will care that he’s gone. That’s the only way you end up in a place like this.

                    Manus continued out to the line. He reached into his work pants and pulled out a pair of gloves, and flipped a switch nearby to turn the red light above him green, signaling he was ready to inspect. The conveyor belt in front of him whirred on, and after a short delay, mage crystals shuffled down one by one. Manus picked each one up, turning it to get a full view of every side, noting the color and the shape to tell him what spell it was for. If there were no dents, cracks, or other malformations with the crystal, it got sent on through to be shipped out. Not many duds got past him. In all seventeen -or was it eighteen?- years, he kept a consistent 99% inspection rating. That and his tenure left him unbothered on his line.

                    “Hey Manus! Long time no see.”

                    Thumps’ fake chuckle pulled him out of his work. Manus passed the crystal along and flipped a switch to turn the line off. Then he turned around to find his manager standing with Shal.

                    “Ha, yeah.” Manus said with a weak smile. “Somethin’ I can help ya with?”

                    “Yes indeed, Manus! Shal’s here to get started on training. Turns out tonight was a free night for him, so I thought who better to pair him up with than the best in the biz. Figured you could show him the ropes, help him put the ‘quality’ in ‘quality assurance’.”

                    Thumps chuckled again, turning to Shal and patting him on the back. Shal almost fell forward, and put on a weak smile of his own as he caught himself.

                    “Well sure, I’d be happy to.” Manus said.

                    “Great! Well Shal, I’m leaving you in great hands, best of luck and I’ll see you in a bit.”

                    Thumps gripped Shal’s hand and shook it, nearly taking Shal’s twig-like arm off. He let go and then Thumps trotted away towards his office, leaving Shal to shake off the pain.

                    “Sorry ‘bout him.” Manus said, “He means well, but he can be a bit much.”

                    “No kidding,” Shal said. “He almost took my arm clean off. So what’s first? How can I help?”

                        Shal seemed to cheer up now that Thumps was gone. That wasn’t much of a surprise to Manus. Thumps came off friendly, but his large stature and the nature of the business gave the old manager an intimidating presence. Manus had never had an issue with him, but Manus had also never threatened his bottom line. Hopefully Shal wouldn’t make that mistake

                        “Well here, come over to the line and we can run through a couple of the basics. The job really ain’t hard, just a matter of knowing what to look for, and dottin’ your t’s and crossin’ your i’s.”

                        Shal stepped over beside Manus in front of the line, with a grin as if he had said something funny.

                        “So first thing’s first, gotta have your gloves on.Can’t be handlin’ this volatile of material without some protection.”

                        Shal held his gloved hands up to Manus.

                        “Oh well good, you’re already ahead of the game. Next step is to turn the line on.” He flipped the switch and the light above them flashed to green. The belt began moving, and crystals soon slid in front of them.

                        “Alright now go ahead and grab that one, I’ll get this one.”

                        They both picked up a crystal. Shal twisted it in his hands while Manus watched him.

                        “What are these made of exactly?” He asked.

                        “Good question,” Manus said. “Couldn’t rightly tell ya. But just know they’re dangerous as hell, so don’t be jittering it too much.”

                        Shal stopped jittering the crystal, and brought it up to his face.

                        “So what am I looking for exactly?”

                        “Well now that’s the tricky part. We have a couple different colors of crystals you’ll see come down the line. First thing to check is, does the color match what we expect?”

                        Manus pointed up to a chart on the wall to their right that laid out all the colors to look for. Shal looked back and forth between the blue on the chart and the crystal in his hand.

                        “Looks right to me I think.” Shal said, looking at Manus for approval as he held the crystal up to him.

                        “And you’d be correct, very good.” Shal looked pleased with himself and went to put the crystal back on the line.

                        “Woah woah woah, slow down. That’s just step one,” Manus said, stopping him.

                        “Next we gotta check the shape,” and Manus pointed to another chart beside the color chart, where a few shapes were drawn out beside a green checkmark, and more shapes below it drawn next to a red X.

                        “Now what do you think, is it an acceptable shape?”

                        Shal rolled the spherical crystal in his hand again and looked at the chart. He looked sure of himself, but then caught something on the bottom of the crystal.

                        “I don’t think so. Looks like there’s a flat part on the bottom of this one. It’s not perfectly round.”

                        Manus must not have been able to hide his surprise, Shal smiled ear to ear.

                        “Well, very good Mr. Shal. Not many people would have been able to pick up on that. Go ahead and toss it in the defect bin.” Manus gestured toward a closed basket on the other side of the conveyor belt, and Shal tossed the crystal at it, and the basket opened up and consumed the crystal, then shut itself.

                        “All right, well that’s really all there is to it. Let me send this one along and we can get our next ones.”

                        Both men continued to inspect the crystals as the night passed. Manus watched as Shal consulted the charts and through the shift he only missed one or two crystals that had some more subtle defects. After what was only a couple hours, Manus paused the line.

                        “Alright, well that looks like the end of our shift. Good work today Shal, I can already tell you’re gonna be a big help around here.”

                        Shal wiped his forehead. “Thank you sir, I’m just glad to be making some good money doing some honest work. My mother’s really sick so she needs-”

                        Manus cut him off, “No need to explain yourself son. We all got our reasons for workin’”

                        And I don’t need you making me feeling any guiltier about this

                        “Say, what’re ya doin’ after this? There’s a bar right down the street I like to get over to once I’m out, care to join me?”

                        “Well sure, I don’t have anything going on,” Shal said. “Thumps said I’d be done once you were and I’d have some more training tomorrow night.”

                        “Perfect, let’s head out then.”


                    ***
                        The Dragon Wagon was dead save for the bartender and an old man asleep in the back at a table, his hand gripping a half empty drink. Manus and Shal each took a seat at the bar, and the bartender, a gruff, bearded man with archaic tattoos up and down his arms, approached them.

                        “Manus, buddy, long time no see,” The bartender said.


                        “Very funny, Darius,“ Manus said.

                        “Who’s your friend?”

                        “Oh this here’s Shal, the new guy.” 

                    They both chuckled, looking at Shaw. He took it on the chin, nodding along while smiling.

                    “Well congrats on the job, new guy, what can I get you both?”

                    “Give us two of my usuals, on me,” Manus said before Shal could answer.

                    “Oh you don’t have to do that,” Shal said as Darius turned and started making the drinks.

                        “Ah it ain’t nothin, Shal. It’s always good to celebrate your first day.”

                        And your last Manus thought.

                        “Well thanks, I appreciate it.” Shal said. “Actually, hold my seat for me, gotta hit the bathroom.”

                        “No problem buddy, I doubt anyone will be itchin’ to take it at this hour,” Manus said.

                        Shal chuckled and left for the restroom in the back, Darius pointing to way as he finished up the drinks. He slid them down in front of Manus.

                        “Enjoy,” the bartender said, and he walked away to get back to cleaning the counter.

                        Manus watched him out of the corner of his eye as he began sipping his drink. He reached a hand into his pocket and felt for some of the crystal dust he had scratched off while they worked earlier. If you knew your stuff, the right kind of defective crystal could produce some sleep-inducing effects. Once Darius turned, Manus’ hand was up sprinkling the dust into Shal’s drink. He stirred it in, then returned to his own drink. After a few minutes, Shal returned to the table and picked up his drink.

                        “So what is your usual?” he asked, looking the drink over and sniffing it.

                        “A beautiful mixture of Dragon sherry and aged pixie dust,” Manus said as he downed a swig of it.

                        “Nothing else takes the edge off quite like it.”

                        Shal swirled the drink in his hand, then took a small drink of it. His eyes lit up and he took a bigger drink.

                        “Woah,” he said, “that does hit the spot. Makes my mouth feel like I just ate a handful of mint.”

                        Manus smiled back, “Yep, that means the pixie dust is fresh,” he lied. He avoided Darius’s curious glance their direction at Shal’s comment. It was tough to say what all the side effects of the dust would be, but Shal continued drinking.

                        “So, what brought you to Crysta-Line?” Shal asked.

                        Manus paused for a moment, thinking back.

                        “Really just the same as you. Needed the money for one reason or another. The factory at that time had just opened, right when the crystal boom was starting, so there was plenty of work to go around. Now it seems like hardly anyone wants to be where I’m at. ‘Cept you, it seems.”

                        Manus tipped his glass toward him.

                        “In fact, shouldn’t a smart young man like you be attendin’ the Academy, studyin’ magic theory or whatever they call it?”

                        “Well it’s like I said, need the money for my mom. We never really had much growing up, always had to scrape by so the Academy wasn’t an option for me. Just been staying at home helping her out since I finished Preliminary School, working odd jobs wherever I could find them. Seems like I finally stuck the landing with this o-”

                        Manus watched Shal as his eyes dropped shut. He opened them again, trying to finish his thought, but found it hard to fight off the crystal-induced sleep. Shal looked at Manus confused.

                        “Woah buddy, looks like you might chugged that down a bit. Ya sure you can handle your stuff?” Manus said.

                        “Hey Darius, care if I pay you tomorrow? Looks like this kid needs some help getting home.

                        “No…I’m fine. Just a little…tired.” Shal said, struggling with every word.

                        “Yeah,” Darius answered. “Get home safe kid. Manus here will help you out. Try not to overdo it next time.”
                        
                        “Yep, c’mon Shal, let’s get going,” Manus said as he stood up from the bar, sliding the two drinks back, as Shal struggled to get up.

                        “Don’t worry, I gotcha buddy. Let’s get ya home.Night Darius, thanks again.” Manus put Shal’s arm around him and walked him out the door, while Darius watched, picking up their drink glasses.

                    ***

                        The shadows coating the driveway cleared the way for the headlamps of Manus’ motorbuggy as he pulled in. He shut the car off, and looked over at Shal in the passenger seat. He sat still, his chest raising and lowering in long intervals.

                    Maybe I used too much dust. It’s better this way at least, that means you won’t feel a thing.

                        Manus got out of the car and walked over to the passenger side. He opened the door and unbuckled Shal out of the seatbelt. Shal was light in his arms as he pulled him up and walked him over to the cellar door. Manus looked over at the barn as they walked. He didn’t hear Corrus, but he could feel him in there, waiting eagerly for his next meal. At the cellar door, Manus set Shal down and fumbled for the right key in the dark. He unlocked and opened up the cellar, and dragged Shal behind him down the stairs into the depths of his workshop. Once they reached the bottom, Manus found the lightswitch, and the room glowed in a sickly yellow light. 

                    The concrete floor was covered in a mixture of dirt and bodily fluids. On one side of the room sat a shelf full of tools, chemicals, paints, and rags. On the other side, a worn, bloody workbench where the tools would fulfill their horrid purpose. Manus dragged Shal to the table and lifted him onto it. He couldn’t tell at this point if he was still breathing. He hoped he wasn’t. He walked back to the shelf behind him and grabbed a couple long strips of leather and a saw. He walked back, and wrapped the leather strips around the table and Shal, securing him in place. Manus picked up the saw, and placed it on Shal’s neck. He looked over the young man, his long gray hair hanging over the table, as he dreamed.

                    I hope it was a good one. I’m sorry.

                    Manus dug the saw into Shal’s neck, moving it back and forth as sinew and flesh gave way to the rusty blade. The table shook under the weight of his strokes as bone struggled to separate.

                    Eventually the saw broke through, cutting into the table. Blood pooled underneath the body, soaking into Shal's grey hair and running over the table. The sound of the dripping blood echoed against the stone walls as Manus pulled the saw up, sliding it through the gap between Shal's head and body.

                                Continuing his work, Manus moved down to each of Shal's limbs, and did the same as before, sawing each limb clean through. Once he was done, Manus dropped the saw, and returned to the shelf. He grabbed three large bags, and walked back. He lifted the severed legs from the table and stuffed them into one bag, then did the same with Shal's arms. Then he slid his torso into the last bag, and gently placed Shal's head on top of it before sealing it and the other bags.

                            Dawn bled in down through the cellar door as dawn crept up on Manus. He grabbed the bag containing Shal's head and torso, and dragged it up the cellar stairs. The cool morning mist clung to him as he walked the bag to the barn. He unlocked the door, and once again the stench of Corrus’ breath filled his lungs. He looked at the great dragon as it sat, proud and unwavering, watching the bag as Manus prepared to throw it. He tossed it in, and without hesitation Corrus dug his maw into the bag, ripping it open.

                            Manus stood there, watching in silence as a uneasy sickness took over him. He watched as Corrus ripped Shal's head from the bag, and ate it like an emperor would eat a grape. Manus turned from the barn and picked, his mind flashing back to the bar and the line at work.

                            Why did this feel different? He was just like the others he had fed to Corrus. Other factory workers, drifters, nobodies. Manus wiped the vomit from his mouth, then slammed the barn door shut and locked it. He shuffled back into the house and shut himself in, grabbing a glass of water before going to bed and shoving the thoughts out of his mind. Once his body hit the mattress, exhaustion set in and put Manus to sleep.


                    ***

                            Manus awoke to the deep wall-shaking wails of what could only be Corrus. He jumped out of bed, rushed down the stairs and grabbed his keys. The wails became deafening as he sprinted over to the barn, the barn key gripped tight in his fist.

                            He gripped the lock in his sweaty hands, struggling to slide the key in. Just as he inserted the key and turned it, the wailing stopped.

                            “No no no, Corrus what've you done.”

                            He dropped the lock and swung the barn door open. He couldn't process what the light revealed to him in the barn.

                            Corrus laid on his side, motionless, with a gaping, bloody hole spanning the entirety of his stomach and chest. Beside him stood Shal, naked and coated in the dragon's dark red blood.

                            “Shal, how?”

                            Manus was at a loss for words staring at Corrus while Shal approached him. 

                            “Manus Lucidious, you're under arrest for the murder of an officer of the law. And probably a lot more than that based on what I saw in that cellar of yours.”

                            The sound of sirens filled the air, and a minute later several more autobuggies with flashing red and blue lights surrounded the barn. Manus looked over them all and back at Shal collapsed to the ground, and his face relaxed. It was finally over. He took a deep, relaxed breath.

                            “Thank you, Shal.”

                            “Don't thank me, Manus, this was all you. Though I can't blame you for not realizing that feeding a dragon an Oozian would only end in disaster for it.”

                            Shal crouched down, meeting Manus on his level. His hair laid slicked back against his head with blood, and the innocent look in his eyes was now replaced with a stern determined gaze. Officers poured into the barn, some investigating the dragon while one began handcuffing Manus and another draped a blanket over Shal.

                            “Now here's the deal Manus,” Shal said. “You and I both know you wouldn't have done this had you not had a man-eating dragon quite literally knocking on your door for a meal. We're going to bring you in to ask you a couple questions, but what we really want to know is if you can help us find the person that sold you this creature.”

                            The letter flashed in Manus's mind, and so did the name he knew Shal would want,

                            Gregorius T. Malinthallow
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Interception</Accordion.Header>
                <Accordion.Body>
                                    Brett sat down at the lobby security booth to start his graveyard shift, pinning his pink slip under the college football trophy he kept on the desk. He checked his phone with one hand and adjusted his chair’s height with the other, settling down for another night of static, fuzzy camera feeds and unsafe doses of caffeine pills. No new messages. He tossed his phone on the desk and logged into the computer, bringing up the twenty four cameras stationed across four floors worth of corners and hallways within Third-Fifth Bank’s walls. Brett rubbed his face with his hands, wiping off the sleep deprivation as best he could before lazily scanning the grid of cameras on his screen.

                    There was nothing on cameras one through twelve, as expected with the lobby closed and the first floor cleared of all the well-dressed bankers and low-level executives who pretended to work for a salary three times his own. The rest of the cameras were mostly the same, save for camera fifteen where John, the old audiophile custodian, vacuumed the purple carpet of the second-floor hallway lined with the portraits of former and current C-level executives. Brett was glad to see him, it had been a while since he’d been on the same shift as John, and he still had to return the Tool CD he borrowed, especially now that Brett had a time limit.

                    Brett continued scanning, paying close attention to the last six cameras on the grid. The first two gave mirrored views of a narrow hallway extending from the special-access elevator, while the next four gave fly-on-the-wall views of a red-carpeted, spacious vestibule, and the giant brass-colored wheel of the vault it led to. Brett’s motivation to perform his job had dropped to an asymptote to zero given the circumstances, but this was the asymptote. When he’d been hired a year ago, his now-retired boss, Chuck, made sure to drill in his head that no matter what, check the safe cameras every hour and sign the digital sheet to say you checked them. It was the only thing that would turn his two-week termination into an instant one. Management would review the log times, so they would know in the morning if he didn’t. After clearing the vault cameras, Brett pulled up the sheet on his second computer monitor, signed it, and picked up his phone to browse back over stale social media posts and advertisements.

                    It was five minutes later that Brett heard the shattering of glass from his computer speakers. He jumped at the sound, nearly throwing his phone as adrenaline and anxiety shot through him. Brett sat up in his chair and darted his eyes to the camera grid. He scanned all the cameras in a fervor top to bottom in an attempt to find the source, and found it seven cameras deep into the list, on the first floor. There on the feed was a shot of a hallway, nearly identical to the one John was busy cleaning on floor two. Instead of portraits, this one’s walls were spackled with awards the bank had received, paintings of nature, and a shattered window at the end of the hall.

                        Brett looked in disbelief as through the window, two dark-dressed figures pulled their way into the hall, clunkily lowering themselves from pulley devices as they stepped over the shards of glass scattered on the carpet. The shorter one pointed down the hallway, and the tall one obliged, rushing down the hallway and bursting into offices while the other did the same in the perpendicular hallway. Brett watched frozen, glued to his chair as he spiraled over how to act. In all two years he’d worked here, the most he had to deal with was a disgruntled customer harassing him after hours. After sitting with the fact that this was above his pay grade, the thieves returned from their respective searches, flashing cards in each of their hands. Brett recognized them: the keys required to get into the special-access elevator down to the vault floor.

                        “Shit,” Brett muttered. “I need to call the cops, this is too much.” He pulled up his dialer and typed in “9 1 1”, but his thumb hovered over the call button, frozen. The pink slip under his trophy flashed in his mind. Two years here, and that’s my reward? he thought. Brett put the phone down, he had a better idea. He shuffled through the drawers of his desk, and eventually pulled out a sticky note hidden under old papers and unused supplies. Logging out of the computer, he pulled up the note and typed in the administrator username and password. “Thanks Chuck,” he muttered while the computer logged back in with administrative privileges. Brett pulled back up the monitoring application, found the right setting, and disabled camera recording. Next, he logged into the footage server, navigating his way to that night’s recordings so far, and wiped them.

                        Now he just had to wait for the thieves to get into the vault, get whatever they were after, and make their grand escape. Brett was content with sitting back and enjoying the show now. Afterwards, he’d rough himself up a bit and make sure to get his alibi straight so no one would become too suspicious. He left the sticky note on the desk, and pulled the cameras back up, excited more than anxious now at the exhilaration at watching a live heist.

                    Brett’s eyes shot back to the second floor, eagerly searching for the two thieves. They were gone. He darted his sight down to the vault floor, thinking they must have already taken the elevator down. None of the six cameras showed anything, just a still feed of the vault. Confused, Brett backtracked through the other cameras. It wasn’t until one of the second-floor cameras sounded a faint ding that he found them. The two rushed out of the elevator into the freshly-vacuumed hallway, and Brett sat now on the edge of his seat, confused.

                    “Wait,” he muttered to himself. “Why didn’t they go down to the vault?”

                    He panicked, thinking of John now and the possibility of him being ambushed by the two thieves. Bouncing between the other second-floor cameras, he found John, pulling his vacuum along down a hallway perpendicular to the elevator hall. The thieves were fast approaching the corner, and Brett panicked.

                    “No no no. Fuck, John, get away from there.” Brett yelled. He pulled up John’s contact in his phone, and just as he almost sent the call, John stepped into an office at the far end of the hallway, and shut the door.

                    “Oh thank Christ.”

                    Brett wasn’t entirely sure the black-garbed duo would hurt the janitor, but he hoped now that John would be none-the-wiser and the thieves would try to avoid him. The two rounded the corner and stopped, looking at each other. The taller one pointed in John’s direction, and the shorter one nodded. The shorter one pulled out a pistol, and the taller one took it from them. Brett’s fear dialed up to ten as they both shuffled down the hallway. The shorter walked back to the elevator to wait, and the taller one made their way to John’s office door. In one motion, they opened the door, raised their arm, and two loud shots rang out through the speakers of the computer as Brett sat stunned. The tall thief continued on callously, walking back to their partner at the elevator. 

                    Brett’s mind was racing. He’d gotten John killed. His only friend at this bank, dead now at the hands of two underestimated robbers. If he called the police now though, they would quickly discover his naive plan to help the thieves, and he’d lose more than his job. He had to stop them, be the hero, and avenge his friend. Resolute, Brett checked his surroundings. His job left him without any useful gear to use, but after scanning his desk, he found what would help: his football trophy. Brett picked it up and read the engraving; top to bottom, it said “2012, University of Wisconsin, MVP, Presented to Brett Honors.”

                    A foot in length with a weighty base, it was the best weapon he had. Brett checked the security cameras again, and confirmed the thieves were by the vault elevator, scanning the access cards to bring it to them. With only minutes to work with, Brett ran from the security booth to the elevators. Beside them he punched his security code into a keypad, unlocking a door to the emergency stairwell. Rushing down the stairs, he hoped to beat the elevator to the vault floor. 

                    Reaching the vault door, Brett punched in his key code into the keypad. It flashed green, and he swung the door open into the barren vestibule leading to the vault. As the door shut behind him, the elevator down the hall to his left made a faint ding. Brett snuck up to the edge of the hallway, falling back against the wall as he held up the trophy. In his mind flashed the gun they’d used to kill John. His mind began racing; should he attack the short one, or the tall one first? Had the taller one handed the gun back to the shorter one, or did they still have it? Maybe it was still better to take out the tall one first and make it an easier fight. Before he could finish his thought, a black figure entered his view, and Brett swung the trophy down with all his might.

                    A dull thump rang out as the figure and the base of the trophy fell to the ground, both broken. Before Brett could determine which one he’d struck, the taller one was on him, answering the question. They grabbed him, trying to tackle him to the ground, but Brett held fast, throwing them away to his side. The tall one regained their composure, and came in to throw a punch at Brett’s face. He blocked it, feeling the pain ring out through his forearm. With his other fist, Brett swung with a haymaker, connecting with the thief’s weak guard. His fist broke through, smashing into the side of their head. They doubled back in pain, but Brett ran at them to follow up with a tackle. He dipped low, driving his shoulder into their chest and ripping them from the ground. Brett carried them back, using all his power to drive their body into the wall behind them. 

                    The thief’s head let out a sharp crack as it made contact with the wall, and Brett let the unconscious body slump to the floor. Hearing movement behind him, Brett turned back to see the shorter thief beginning to stir, snapping back to reality. After slowly sitting up, they recollected their thoughts and looked at Brett and their limp partner. Immediately they shuffled their hands around their waste, and Brett realized they were searching for the gun. He started running at them, and as if he were punting, threw his foot back and slammed it into the short robber’s head, sending them sliding both across the floor and back into unconsciousness.

                    Brett dove on top of them, feeling around until he pulled out the pistol they’d almost used to kill him. It shone silver in the bright lighting, detailed with engravings on the barrel and grip. He held on to it, pointed between each of the thieves in case either tried to move. When he confirmed they were both down for the count, he let out a heavy breath, dropping his hands onto his knees as the adrenaline started to leave his body. He was shaking, but proud of what he’d done.

                    After looping the gun in his belt, he gathered up each of the thieves, dragging them into the elevator so he could watch them up in the lobby while he called the police. Brett let the door close and pressed “L”. A ding reverberated through the steel box as it halted, and the door slid open, revealing the dark empty lobby. Brett grabbed both thieves by the back of their necks, and dragged them out of the elevator. He then stood up straight, letting the elevator door close. While he stretched his lower back, he felt the gun get pulled from his belt. He twisted around to find John pointing the gun at him.

                        “John, oh my god, I thought you were fucking dead. What are you doing?” Brett said, glancing between the gun and John’s angry face

                    “This shouldn’t have been how it went, Brett. You were one of the good ones. I was trying to leave you out of this.” John said.

                    “What the fuck are you talking about? I saw them shoot you, John. You’re a part of this? How?” Brett asked.

                    “You saw what we wanted you to see, John. I walked into that office so it looked like I’d died because I knew you’d be watching. My grandkids would never shoot me.”

                    “Wait, these are your grandkids?” Brett said, looking down at the unconscious lumps at his feet. “John, why the hell are you getting your family to rob this place. I thought you were only a couple years from retiring?”
                    “It was their idea, actually. I was complaining about all the bullshit this place puts me through one night, and my granddaughter thought I deserved better. They’d apparently hit up a couple of Walmarts around town, so I went with it. I know it was wrong, but it sure felt right.” John said, a grin on his face.

                    He continued, “Now Brett, I went to your station, I saw you turned the recording software off. Clearly you’re not a fan of this place either. I don’t know why you had a change of heart and beat the shit out of my grandkids, but I like you. Now, I really don’t want to do this. What I'd like instead is for us to all walk out of here in as many pieces as we can. So I’ll make you a deal.”

                    “What?” Brett asked.

                    “You help me get these two in a comfortable spot for when they wake up, and you and I go down there and raid the vault ourselves. You take a cut of the cash, and we both go our separate ways. We’ll rough you up a bit first, and you can tell people in the morning what happened; you got jumped and some mysterious thieves made off with the money and wiped the tapes. It’s either that, or this,” and he motioned at the gun.

                    Brett’s indecision lasted only a few seconds as his thoughts whirled between the relief of John still being alive, the gun pointed at him, and the pink slip he had waiting for him back on his desk. The choice was simple.

                    “You have a deal, let’s get down there.”

                    “That’s what I like to hear.” John said, chuckling. 

                    He lowered the gun and slid it into his pocket. He and Brett slid the grandkids over to a nearby wall, propping them up and inspecting them for major injuries. After confirming they both looked fine for the most part, the two thieves grabbed the key cards from the kids, and walked over to the elevator. They both walked in and turned to face the lobby.

                    The doors slid shut, and a gun shot rang out from the elevator.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>Trial by Fire</Accordion.Header>
                <Accordion.Body>
                                    Volux stood before the Council of Dragons, her wings and snout bound by rusted iron chains. The council sat in a single-rowed colosseum, with ornate banners underneath each member, while she occupied the inner circle below, dirt and grass beneath her feet. Sunlight fell over all of them from a clear sky, giving her worn amber scales a faint glow and illuminating the immaculate banners under each dragon. Each banner contained ancient draconic symbols woven within them, which told the history of the corresponding Dragon house through words or images. Volux scanned the banners, bouncing between each one and the head of the house who stood upon it now, judging her. 

                    She lingered on one, the house of Flamesong’s banner, where the entire piece was enveloped by the image of the gargantuan Grimbrax, the house’s founder. His scarlet scales shimmered on the fabric as he washed a field of human soldiers in a wine-red blaze. She snorted when her eyes raised, locking with the diminutive dragon who now sat atop it, Boragrim. How the mighty have fallen she thought, as the lizard looked down on her haughtily. Volux continued on, recalling her history lessons as she pulled the rest of the great houses from her memories. Glimmerscale, Stormseye, Stoneclaw, Tideripper, the list of houses went on as she viewed their banners. So much history, bathed in blood flashed through her mind, as most of the banners were similar to house Flamesong’s; bathed in images of triumph, of war, of conquer. Only a few of the houses’ banners were coated in words, lengthy tales of their history. Even these were filled with the same ‘heroic’ tales, simply told more elegantly than their siblings. 

                    Killing humans, conquering humans, holding them under their great claws, it all sickened her to see, more so to see each househead stand above them, eying her with gazes varying from pity, to contempt, to bloodlust. Only from the great central banner of the royal house Suneater was there no such gaze, because no dragon sat upon it. The banner was beautiful, larger than all the rest, and ornately decorated with golden thread. Shimmering words wrapped the great image in the center, each listing the house’s ancestors. The portrait shimmered brighter than the surrounding words, depicting the oldest image among them all; the first great golden dragon king, Drexus, Light poured out from behind his proud head as he stood above an array of bowing dragons, the firsts of their own houses. 

                    Volux lingered on it, scanning each of the names around the depiction until she found it, the gap in the weave where the thread had been stripped, and on either side, the name of her hatchling brother, and her father. After a moment, the heads watching her turned inwards, all watching the central royal seat as an aged, dark purple-scaled dragon with a flowing white beard sat on it. The househeads bowed their necks in respect, while Volux and the aged dragon peered into each others’ eyes, each trying to read the other’s thoughts. After a quiet, tense moment, Volux spoke through her chains.

                    “Grandmother, you look well.” she muffled out.
                        “Do not dare speak, child.” her grandmother retorted. She recomposed herself.

                        “You stand here accused of high treason, patricide, regicide, and conspiring with humans.” she announced throughout the coliseum. Volux stared stoically at her grandmother as she made the announcement. 

                    “Have you no shame for what you have done?” Blurted out Marrestra Tideripper, her turquoise scales glimmering in the sunlight as her face was full of rage. She was close to Volux’s age, having taken up her house's mantle after her mother had fallen years earlier to pirates.

                    “Marrestra, hold your tongue,” Volux’s grandmother sternly commanded. “We must have order if we are to get through this quickly. I share your sentiment, but I have a son I must grieve, and an oath-daughter to console.”

                    “I understand Groleksa,” Marrestra responded, “and while I know you grieve more than any of us, we all too have lost someone dear to us. We are all granted a single inquiry at this hearing, are we not? Well I would like to use mine. So I ask you again, traitor, have you no shame for what you did?”.

                        Volux waited for her grandmother to demand silence again, but Groleska only stared at Marrestra for a moment before taking a deep sigh of resignation. “You are correct,” she said, turning back to Volux, “so answer for us. Do you?” The other members of the council stared at her down, anticipating what she might say.

                        “No,” Volux responded. “I feel no ounce of shame for what I’ve done. All of it I did proudly and without remorse.”

                        Marrestra’s eyes glowed with rage, her scales shifting and tensing as she held herself from jumping down to gouge out her throat. The other dragons stared or shook their heads, while her grandmother only held her eyes shut.

                        “You disgust me.” Marresta spat.

                        “Please, quiet now,” Groleska said sternly. “You’ve asked your question, now let us move on to the evidence of these accusations, unless anyone else wishes to interject.” The rest stayed silent.

                        “Very well then,” Groleska continued. “Eye witness accounts from various guards of your father, King Ferrexus II, rest his soul, placed you and a small band of humans at his bedside after screaming and the sounds of a struggle were heard from within his and your mother’s chambers.The few guards that lived through the encounter claimed they burst in to find you standing over him, a bloodied warblade in your mouth. The humans with you were said to have been weaving strange signs in the air, conjuring fire and other elements while they made their escape. You however, remained behind, jumping at the guards, attacking them, until you were finally subdued.” Volux could see the looks of shock on the council members at what had been recounted. As her grandmother finished, a large, aged gray dragon, Taraskus, stood in a panic from his spot, shaking his head.

                        “No, no, this cannot be true. I know Volux, I trained her myself since she was a hatchling. She would never have done something like this. And humans weaving fire out of thin air? This has to be some misunderstanding. Volux, please tell us the truth. You were helping to fight these wretched humans, to defend your father, right?”

                        Groleska did not interject this time, but let the question stand, eyeing Volux coldly.	Volux stretched her jaw in the chains to try and loosen their grip on her.

                        “There is no misunderstanding. I was there that night. I snuck the humans to my father’s bedchambers, and together, we killed my father.” she explained. The chains were digging into her flesh now, so she kept her answers concise. Volux wanted the trial to be a short one for her own sake. She knew what the outcome would be, and wanted it over with, showing as little weakness as possible in the process. Her vile kin could not see her suffer, no matter how guilty they believed her to be.

                            A silence fell over the room at her bold admission. Taraskus no longer looked at her, instead only shook his head, tears falling to the floor as he muttered to himself. Only her grandmother seemed unaffected by the words. If she felt anything now for her granddaughter or her crimes, she didn't show it. The other members of the council fell somewhere between the two, except for Marresta, who still seethed with a cold rage.

                            After the brief pause, Groleska spoke, "So there we have it, an admission if I’ve ever heard one. Well, granddaughter of mine, you have disgraced not only yourself, but all those who’ve come before you. Throughout my life, I’ve seen countless ages of humans come and go, and through none of them have I ever met a dragon who would so quickly throw their life away for them, let alone in such a way as you have. The debaucherous little creatures have served under us out of fear and worship for hundreds of years, but now it seems they’ve grown a bit of a spine with your help and the help of whatever this strange new ability is. No matter though, much like all other human inventions, they will crumble and fall before us, and we will stamp these dissenters out before they can even so much as blink in our direction again.” 

                    Her words echoed through the stadium as she finished her speech. The essence of the words she spoke struck a nerve now in Volux; the apathy and the hubris they carried, that all the dragons here held inside them for the humans they’ve abused since coming to this land. As she stewed in her quiet rage now, Groleska continued. “So, if there are no more questions for the accused, I believe we can move to the sentencing.” As she finished however, the creak of a great door somewhere behind her grandmother swung open and shut, followed by the sound of a shrill voice choked up by tears that Volux knew well. Mother she thought, why have you come here?

                    “Queen Persysus, you should be mourning right now. Please, you shouldn’t be here,” Groleska said as Volux’s mother approached the lip of Groleska’s place. Her grandmother made room for her. Persysus’ scales were an oily amethyst, absorbing the sunlight as it hit her. Her eyes were worn, tired with grief as she stared down at her daughter. Volux was caught off guard, finding herself unable to meet her mother’s gaze.

                    “What I should be doing right now, is standing beside my king, my oathsworn, and my two beautiful hatchlings. But here I am, looking down at my only daughter, having to face the fact that she took him, her own father, away from us,” Persysus said shakily, having to pause to allow herself time to breath. Groleska did not try to stop her, her empathy interfering with her duty now. Volux felt her own emotions rising up in her now, pain for her mother’s loss, and for her own. She held it back, however, knowing what was at stake. If she backed down from her own convictions now, she would never forgive herself. Steadfastly, she locked eyes with Persysus, and in them she saw only pain. 

                    Persysus continued, “Volux, how could you do this to us, to your family? Your brother must now grow up without a father, and me without the love of my life and my precious daughter. You’ve committed the gravest sin, and for what? For humans? I heard everything: your confession, the band of those wretched insects you were caught with in your father’s chambers. Just answer me Volux, why? We gave you the best life we could have hoped for, and now you’ve thrown it all away.” She was screaming now, in hysterics while the other dragons sat quietly, mourning her loss.
                    You’re just like them, mother, Volux thought. The words hit deeply in her heart, but still it was all for naught. No matter what she said now, it would change nothing. Mother had already resigned her to her death, and the rot of the dragon’s hatred for humans was spread too deep, down to the roots. None of it could stay.

                    “I’ll tell you why, Mother.” Volux yelled out, stretching against the chains that held her snout now, even as they cut into her flesh. Persysus was taken aback to hear her daughter’s voice so commandingly. 

                    “When I was a hatchling, remember when I went missing? I’d flown too far into the woods near one of our outposts. Father had brought me as part of a military tour to teach me more about our kingdom, but I was far more concerned with playing, so he let me fly off after a bit. I saw a herd of deer weaving through the trees, and decided to chase them. I dove into the forest, winding through branches, trying to maneuver my growing wings when I flew straight into a broken branch, stabbing me in the stomach. It sent me barreling to the ground, where I sat bleeding out expecting to die. Then a family of humans, two siblings, found me and brought me to their hut in the forest. I knew immediately they would finish me off and keep me as a trophy, but their parents were kind and gentle. They took me in, bandaged me up, and let me rest and heal while the children, Thomas and Celia, and I played together. They fed me, gave me a place to sleep, and once I was healed, let me go on my way back to where I belonged. 

                    I went back to the outpost, and you and father immediately flew to me. I was so happy to see you both again, and excited to share my story about the kind people I’d met and how they helped me. When I shared the news, you told me how great that was, but Father, he had a different look on his face. After that, you kept me close to your side, but eventually I made my way back to that cabin in the woods, to play with Thomas and Celia again, and thank their parents for what they’d done. But instead, what I found was just a crater of charred wood, and the dusty remains of the four of them, all where their dinner table had been. Since then, I’ve known who the real insects of this world are; we dragons who pretend to be so mighty and grand, but really are just monsters, down to our core. We’ve kept humans under our claws, enslaved and tortured for too long, and now it’s time for our disastrous age to end.

                    And when I was with them, those humans you despise so much, I found the way it could be done. When Thomas and Ceila carried me back and treated my wounds, some of my blood got on them. Their eyes began to glow like ours, and their strength and speed became that of a full grown adult. When their parents realized what we’d discovered, they told them to never touch my blood again, that it was dangerous, and I needed to heal. But we were mischievous, and some nights we would sneak out and I cut myself open again, just enough for their powers to awaken, and we would experiment. Thomas could harness flight and manifest lightning from his hands. Celia could punch through trees and breathe fire. It was amazing, something I knew no one in this world had ever seen before, and that I had to keep it secret. I knew if any of you found out about it, dragons would wipe out humans altogether to keep our precious status quo. If only I’d known how much to keep secret, then maybe that family would still be here today. It’s too late now though, so now all I can do is try to right your wrongs.” 

                    With that, Volux let out a monstrous roar, the chains tearing her flesh. The dragon council, Groleska, and her mother now only looked at her with fear, the fear prey feel when they know they’ve been caught. The colosseum began to rumble as dozens of humans burst out from the ground beneath her, casting out waves of lightning, fire, and ice at the dragon council. You planted the seeds for this war, Volux thought to herself, now reap what you’ve sown.

                </Accordion.Body>
            </Accordion.Item>
            </Accordion>
    </Container>
    )
}

export default Stories;